import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import under_construction from "../images/under_construction.svg"

const ProjektePage = () => (
  <Layout>
    <Seo title="Projekte" />
    <div className="max-w-7xl mx-auto px-4 py-4 md:px-0 md:py-12">
      <h2 className="text-3xl font-extrabold text-gray-900">Projekte</h2>
      <p className="mt-4 text-lg text-gray-500 text-left">
        <img
          src={under_construction}
          className="w-full md:w-1/3 mb-4"
          alt="Projekt im Aufbau"
        />
        Diese Seite ist zurzeit noch in der Entstehung, wenn Sie jedoch nicht
        warten können oder wollen,{" "}
        <a
          className="underline text-blue-500"
          href="mailto:info@adoser.ch?subject=Anfrage Projektunterlagen"
        >
          kontaktieren
        </a>{" "}
        Sie uns. Wir Präsentieren Ihnen gerne zahlreiche abgeschlossene und
        Laufende Projekte.
      </p>
    </div>
  </Layout>
)

export default ProjektePage
